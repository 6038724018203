import { HTTPMethod } from '@/lib/handlers/RouteHandler'
import { CSSVariable, NonNullableProps, Maybe, NullableProps } from './types'

// TODO: typed "fetch" for client side and migrate on it
export class ClientFetch<
    TResponse extends Record<string, any>,
    TBody extends Record<string, any> = Record<string, any>,
    TError extends Record<string, any> = Record<string, any>,
> {
    public options: IClientFetchOptions<TBody>

    public _ok: Maybe<boolean> = null
    public code: Maybe<number> = null

    public error: Maybe<TError> = null
    public response: Maybe<TResponse> = null

    constructor(fetchOptions: IClientFetchOptions<TBody>) {
        this.options = fetchOptions
    }

    /**
     * Makes an HTTP request with specified request data.
     * @returns {Promise<boolean>} Whether the responce convertation to JSON was successful.
     */
    public async fetch(): Promise<boolean> {
        const { url, body, method, authentication } = this.options

        const request = await fetch(url, {
            body: JSON.stringify(body),
            method: method || HTTPMethod.GET,
            headers: {
                authentication: authentication as string
            }
        })

        this._ok = request.ok
        this.code = request.status

        const data = await request.json()

        try {
            if (!request.ok) {
                this.error = data
            } else {
                this.response = data
            }
        } catch {
            return false
        }

        return true
    }

    /**
     * **[TYPE-GUARD]** Determines whether the request fetching process was completed.
     * @returns Whether the request fetching process was completed.
     */
    public isFetched(): this is FinishedClientFetch<ClientFetch<TResponse, TBody, TError>> {
        return this._ok !== null
    }

    /**
     * **[TYPE-GUARD]** Determines whether the responce is not OK.
     *
     * - Requires the usage of {@link ClientFetch.isFetched()} type-guard before running this method.
     * @returns Whether the request failed.
     */
    public isErrored?(): this is ErroredClientFetch<ClientFetch<TResponse, TBody, TError>> {
        return this._ok == false
    }

    /**
     * Retrieves the details of the request error.
     *
     * - Requires the usage of {@link ClientFetch.isFetched()} and
     * {@link ClientFetch.isErrored()} type-guards before running this method.
     * @returns Request error details object.
     */
    public getError?(): IRequestError<TError> {
        if (!this.isFetched() || !this.isErrored()) {
            return {
                code: 0,
                responce: {} as any
            }
        }

        return {
            code: this.code,
            responce: this.error
        }
    }

    /**
     * Retrieves the request JSON responce.
     *
     * - Requires the usage of {@link ClientFetch.isFetched()} type-guard before running this method.
     * @returns Request JSON responce object.
     */
    public getResult?(): TResponse {
        if (!this.isFetched() || !this.isErrored()) {
            return null as any
        }

        return this.response
    }
}

export type FinishedClientFetch<TClientFetch extends ClientFetch<any>> = NonNullableProps<
    TClientFetch,
    '_ok' | 'code' | 'response' | 'isErrored' | 'getError' | 'getResult'
>
export type ErroredClientFetch<
    TClientFetch extends ClientFetch<any> | FinishedClientFetch<any>
> = NonNullableProps<TClientFetch, 'error'> & NullableProps<TClientFetch, 'response'>

export interface IRequestError<TError extends Record<string, any>> {
    code: number,
    responce: TError
}


export interface IClientFetchOptions<TBody extends object> {
    url: string
    method?: HTTPMethod
    body?: TBody
    authentication?: Maybe<string>
}

/**
 * Returns the border style string based on its size and color.
 * @param size Border size in pixels.
 * @param color Border color.
 * @returns Border style string.
 */
export const getBorderStyle = (size: number, color: NamedColor | CSSVariable): string => {
    return `${size}px solid ${color}`
}

// source: node_modules/csstype/index.d.ts:20472
export type NamedColor =
    | 'aliceblue'
    | 'antiquewhite'
    | 'aqua'
    | 'aquamarine'
    | 'azure'
    | 'beige'
    | 'bisque'
    | 'black'
    | 'blanchedalmond'
    | 'blue'
    | 'blueviolet'
    | 'brown'
    | 'burlywood'
    | 'cadetblue'
    | 'chartreuse'
    | 'chocolate'
    | 'coral'
    | 'cornflowerblue'
    | 'cornsilk'
    | 'crimson'
    | 'cyan'
    | 'darkblue'
    | 'darkcyan'
    | 'darkgoldenrod'
    | 'darkgray'
    | 'darkgreen'
    | 'darkgrey'
    | 'darkkhaki'
    | 'darkmagenta'
    | 'darkolivegreen'
    | 'darkorange'
    | 'darkorchid'
    | 'darkred'
    | 'darksalmon'
    | 'darkseagreen'
    | 'darkslateblue'
    | 'darkslategray'
    | 'darkslategrey'
    | 'darkturquoise'
    | 'darkviolet'
    | 'deeppink'
    | 'deepskyblue'
    | 'dimgray'
    | 'dimgrey'
    | 'dodgerblue'
    | 'firebrick'
    | 'floralwhite'
    | 'forestgreen'
    | 'fuchsia'
    | 'gainsboro'
    | 'ghostwhite'
    | 'gold'
    | 'goldenrod'
    | 'gray'
    | 'green'
    | 'greenyellow'
    | 'grey'
    | 'honeydew'
    | 'hotpink'
    | 'indianred'
    | 'indigo'
    | 'ivory'
    | 'khaki'
    | 'lavender'
    | 'lavenderblush'
    | 'lawngreen'
    | 'lemonchiffon'
    | 'lightblue'
    | 'lightcoral'
    | 'lightcyan'
    | 'lightgoldenrodyellow'
    | 'lightgray'
    | 'lightgreen'
    | 'lightgrey'
    | 'lightpink'
    | 'lightsalmon'
    | 'lightseagreen'
    | 'lightskyblue'
    | 'lightslategray'
    | 'lightslategrey'
    | 'lightsteelblue'
    | 'lightyellow'
    | 'lime'
    | 'limegreen'
    | 'linen'
    | 'magenta'
    | 'maroon'
    | 'mediumaquamarine'
    | 'mediumblue'
    | 'mediumorchid'
    | 'mediumpurple'
    | 'mediumseagreen'
    | 'mediumslateblue'
    | 'mediumspringgreen'
    | 'mediumturquoise'
    | 'mediumvioletred'
    | 'midnightblue'
    | 'mintcream'
    | 'mistyrose'
    | 'moccasin'
    | 'navajowhite'
    | 'navy'
    | 'oldlace'
    | 'olive'
    | 'olivedrab'
    | 'orange'
    | 'orangered'
    | 'orchid'
    | 'palegoldenrod'
    | 'palegreen'
    | 'paleturquoise'
    | 'palevioletred'
    | 'papayawhip'
    | 'peachpuff'
    | 'peru'
    | 'pink'
    | 'plum'
    | 'powderblue'
    | 'purple'
    | 'rebeccapurple'
    | 'red'
    | 'rosybrown'
    | 'royalblue'
    | 'saddlebrown'
    | 'salmon'
    | 'sandybrown'
    | 'seagreen'
    | 'seashell'
    | 'sienna'
    | 'silver'
    | 'skyblue'
    | 'slateblue'
    | 'slategray'
    | 'slategrey'
    | 'snow'
    | 'springgreen'
    | 'steelblue'
    | 'tan'
    | 'teal'
    | 'thistle'
    | 'tomato'
    | 'transparent'
    | 'turquoise'
    | 'violet'
    | 'wheat'
    | 'white'
    | 'whitesmoke'
    | 'yellow'
    | 'yellowgreen'
