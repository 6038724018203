import { Maybe } from './misc/utils/types'

export interface IUser {
    id: string
    username: string
    firstName: Maybe<string>
    lastName: Maybe<string>
    middleName: Maybe<string>
    email: string
    password: string
    role: UserRole
    verified: boolean
    verifyToken: Maybe<string>,
    passwordResetToken: Maybe<string>
    createdAt: number
}

export enum UserRole {
    USER = 0,
    MODERATOR = 1,
    ADMIN = 2
}

export const userRoles = ['User', 'Moderator', 'Admin']
export const userRolesInRussian = ['Пользователь', 'Модератор', 'Админитратор']

export type RawUserData = Pick<
    IUser,
    'id' | 'username' | 'email' | 'password' | 'role' |
    'firstName' | 'lastName' | 'verified' | 'verifyToken' |
    'passwordResetToken' | 'createdAt'
>
